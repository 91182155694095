import invariant from 'invariant';
import PropTypes from 'prop-types';
import 'react-immutable-proptypes';
import { Map, fromJS } from 'immutable';
import withConformsTo from '@dbh/with-conforms-to-for-production-www';
import rison from 'rison-node';
import { omit } from '@dbh/lodash-extra';
import { DBH_QUERY_PARAMETER_NAME, decodeQueryParameter } from '@dbh/make-api-url';
import _isString from 'lodash/isString';
import { parse } from 'url';
import _startsWith from 'lodash/startsWith';
import { HASH_PAGE_START } from '@dbh/admindev-constants';
import _endsWith from 'lodash/endsWith';
import '@dbh/generic-types';
import { isAnalyticsQueryParameter } from '@dbh/analytics';
import _noop from 'lodash/noop';
import 'is-valid-hostname';
import '@dbh/create-custom-react-prop-type';

/*
 *
 * `isUrlFragmentIdentifier`: `@dbh/urls`.
 *
 *//**
 * Returns `true` if the given string starts with `#`.
 * @param {string} maybeUrlFragment .
 * @return {boolean} Is `maybeUrlFragment` an `URL` fragment.
 */const isUrlFragmentIdentifier=a=>a&&a.charAt&&"#"===a.charAt(0);var isUrlFragmentIdentifier$1 = isUrlFragmentIdentifier;

/*
 *
 * Constants: `@dbh/urls`.
 *
 */const URL_PRODUCTION_WWW="https://www.daybreakhotels.com";const EXAMPLE_URL="http://example.com";

const transformWebpagesDataForStaging=withConformsTo("transformWebpagesDataForStaging",[],a=>(invariant(!1,"(`transformWebpagesDataForStaging`) Expected to be called only in development."),a));// Transform the `href` value in the `paramLinkEntry` Map. Example: from 'https://www.daybreakhotels.com'
// to: 'https://test.daybreakhotels.com' or to: 'https://dev.daybreakhotels.localhost.daybreakhotels.com'.
/**
 * The `/webpages` `API` returns `URLs` for the production environment
 * ('https://www.daybreakhotels.com'), but we want to adapt them to the staging
 * and development environments.
 * @param {Immutable.Map} webpagesData .
 * @param {string} locationOriginFromServer .
 * @return {Immutable.Map} .
 */var transformWebpagesDataForStaging$1 = transformWebpagesDataForStaging;

const defaultOptions={dbhQueryParameterOnly:!1},extractQueryParametersFromUrl=withConformsTo("extractQueryParametersFromUrl",[],(a,b)=>{if(!a)return Map();const{dbhQueryParameterOnly:c}={...defaultOptions,...b},d=a.replace(/^[^?]*\?|(email=[^&]*)\+/g,(a,b)=>b?b+"%2B":""),e=new URLSearchParams(d),f=e.get(DBH_QUERY_PARAMETER_NAME);// The `+` character in a URL will be interpreted as a space in query parameters;
// therefore, to represent an actual plus sign, it must be percent-encoded as `%2B`.
// This code removes the question mark at the beginning of the string and encodes the
// `+` (plus sign) in the `email` query parameter. This front end encoding is a fallback,
// because the backend should encode `+` in the links it prints in the emails.
// @example from: `test+123@daybreakhotels.com` to: `test%2B123@daybreakhotels.com`.
// Convert the string parameters to an object.
// Decode the `dbhq` parameter, if exists.
let g={};if(f){// Decode the query before passing it to `rison` because it was encoded,
// with `@dbh/make-api-url/urlEncoders#encodeQueryParameter`
// in 'makeUrlWithQuery'.
const a=decodeQueryParameter(f);g=rison.decode(a);}// If other parameters are allowed, we add them.
let h={};c||(h=omit(Object.fromEntries(e.entries()),DBH_QUERY_PARAMETER_NAME));// If `dbhQueryParameterOnly` is false, and if "something=other" is in
// `traditionalParameters`, and `something:another` is in `risonParameters`,
// something will be "another" in the output.
const i=fromJS({...h,...g});return i});/**
 * Extracts and returns the query parameters in the given `URL`.
 * @param {string?} url .
 * @param {Object?} options .
 * @param {boolean} options.dbhQueryParameterOnly If `true`, only take the
 * values inside the `dbhq` `rison` format query parameter.
 * @return {Immutable.Map} Empty if a falsy `URL` is passed.
 */var extractQueryParametersFromUrl$1 = extractQueryParametersFromUrl;

/**
 * Given the pathname part of an url, returns the last path segment
 * typecasted as string (if convertible to number according to `isNaN`).
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/isNaN}
 * @param {string} pathname .
 * @return {string?} A number if found, otherwise `undefined`.
 */const getTrailingNumberFromPathname=withConformsTo("getTrailingNumberFromPathname",[],a=>{var b;return null==(b=a.match(/\/(\d+)$/))?void 0:b[1]});var getTrailingNumberFromPathname$1 = getTrailingNumberFromPathname;

/**
 * Returns `true` if the given url is "external".
 * @param {string} possiblyExternalUrl A `URL`.
 * @return {boolean} Is `possiblyExternalUrl` external.
 */const isExternalUrl=a=>{if(!_isString(a))return !1;const b=parse(a),{protocol:c,host:d}=b,e=[/^www\.daybreakhotels\.com$/,/^staging\.daybreakhotels\.com$/,/^test(-.*?)?\.daybreakhotels\.com$/,/^localhost:3000$/,/^[\w]+.daybreakhotels.localhost$/].some(a=>a.test(d));// `isDbhHost` will be true if any of the regex match the host
// We consider the url external if it has a protocol (absolute url) and
// the host is not one of the dbh prod/staging/test hosts.
return !!c&&!e};var isExternalUrl$1 = isExternalUrl;

/**
 * Returns `true` if a link is a valid local anchor link.
 * @param {string} link A link.
 * @throws If `link` is the `URL` fragment identifier of a hash page.
 * @return {boolean} Is the link a valid local anchor link.
 */const isLocalAnchorLink=a=>!!a&&(invariant(!_startsWith(a,HASH_PAGE_START),"(`isLocalAnchorLink`) You have passed a string that we normally use at the\n    start of `URL` hash fragments (passed: `"+a+"`).\n    Remember that you can only pass a relative `URL` to `@dbh/link`,\n    if the intent was to pass an `URL` plus hash at the end (@example `/IT/en-GB/something#dbh/ipsum`),\n    otherwise if your intent was to pass an anchor link (string starting with `#`),\n    please change it so that it is something like `#dbh__loremipsumdolor` instead,\n    so that it cannot be confused with our `URL` fragments."),"#"===a.charAt(0));var isLocalAnchorLink$1 = isLocalAnchorLink;

/**
 * Given a string returns `true` if the URL is relative (absolute `URLs` without
 * protocol are considered relative), `false` otherwise.
 * @param {string} paramUrl .
 * @return {boolean} .
 */const isRelativeUrl=withConformsTo("isRelativeUrl",["paramUrl",PropTypes.string.isRequired],a=>a.startsWith("/")||!a.match(/^(http|https):\/\//));var isRelativeUrl$1 = isRelativeUrl;

const isPossiblyValidCountry=a=>a&&2===a.length;const isPossiblyValidLocale=a=>a&&5===a.length;/**
 * Validate a `react-router` "link object".
 * @param {Object} obj The react router link.
 * @param {string} obj.pathname The `pathname`, it should not be an absolute `URL`.
 */const validateReactRouterLinkObject=withConformsTo("validateReactRouterLinkObject",[],a=>{});

const getLocaleFromUrl=a=>a.split("/")[2];const getCountryFromUrl=a=>a.split("/")[1];/**
 * Extracts and returns the country and locale from the given `URL`.
 * @param {string?} paramUrl .
 * @return {Object} .
 */const getCountryAndLocaleFromUrl=withConformsTo("getCountryAndLocaleFromUrl",[],a=>{if(!a)return {};const b=getLocaleFromUrl(a),c=getCountryFromUrl(a);if(!isPossiblyValidCountry(c)||!isPossiblyValidLocale(b)){// Ok, it failed, maybe it is an absolute URL.
const b=!isRelativeUrl$1(a);if(b){// If it is not internal, extracting country and locale doesn't make sense.
const b=!isExternalUrl$1(a);if(b){// It is an internal and absolute URL, let's extract country and locale.
const b=new URL(a),{pathname:c}=b;return {locale:getLocaleFromUrl(c),country:getCountryFromUrl(c)}}}}return {locale:b,country:c}});var getCountryAndLocaleFromUrl$1 = getCountryAndLocaleFromUrl;

/*
 *
 * `endsWithSlash`: `@dbh/urls`.
 *
 *//**
 * Returns `true` if the given parameter ends with `/`. If the parameter is not
 * a string it gets typecasted.
 * @param {string} paramUrl .
 * @return {boolean} Does `paramUrl` end with `/`.
 */const endsWithSlash=a=>_endsWith(a,"/");var endsWithSlash$1 = endsWithSlash;

const isValidUrl=a=>!!a;/**
 * Returns `true` if the given url is empty or not secure (HTTP).
 * @param {string} paramUrl A url.
 * @return {boolean} Is `paramUrl` secure (HTTPS).
 *//**
 * Returns `true` if the given url is clearly invalid and malformed.
 * @param {string} paramUrl A url.
 * @return {boolean} Is `paramUrl` invalid/malformed.
 */var isValidUrl$1 = isValidUrl;

/*
 *
 * `isUrlStagingOrLocalhost`: `@dbh/urls`.
 *
 */const localhostRegex=/^(http(s)?:\/\/)?(www\.)?(dev.daybreakhotels.localhost|localhost|staging|test|test-)/i,isUrlStagingOrLocalhost=a=>!!a&&localhostRegex.test(a);/**
 * Returns `true` if the domain of the url is `test` or `localhost`.
 * @param {string} paramUrl A url.
 * @return {boolean} Does `paramUrl` represent a test domain or localhost.
 */var isUrlStagingOrLocalhost$1 = isUrlStagingOrLocalhost;

/*
 *
 * `isUrlPhoneNumber`: `@dbh/urls`.
 *
 *//**
 * Returns `true` if the given `URL` starts with `tel:`.
 * @param {string} url .
 * @return {boolean} Is the given `URL` an `anchor` phone number.
 */const isUrlPhoneNumber=a=>/^tel:.+/i.test(a);var isUrlPhoneNumber$1 = isUrlPhoneNumber;

/**
 * Converts given `URL` to a new `URL` on the given domainOrSubdomain or subdomain.
 * @param {string} paramUrl Any full `URL`.
 * @param {string} domainOrSubdomain .
 * @return {string} The provided `URL`, with the domainOrSubdomain replaced.
 */const convertUrlToDBHDomain=withConformsTo("convertUrlToDBHDomain",[],(a,b)=>{const c="dev"===b?"dev.daybreakhotels.localhost":b+".daybreakhotels.com",d=new URL(a);return d.host=c,d.href});var convertUrlToDBHDomain$1 = convertUrlToDBHDomain;

/**
 * Appends the provided query parameters to the given `URL`.
 * @param {string|URL} inputUrl Relative or absolute.
 * @param {Object} queryParams TO be appended to the given `URL`.
 * @return {string} The provided `URL` with the provided query parameters.
 */const appendQueryParams=withConformsTo("appendQueryParams",[],(a,b)=>{const c=Object.keys(b),d=a instanceof URL;// Return the incoming link if there are no query parameters to append.
if(0===c.length)return d?a.toString():a;const e=!d&&isRelativeUrl$1(a),f=d?a:new URL(a,e?EXAMPLE_URL:void 0);// `URL` constructor doesn't accept relative `URLs` without base
// @see {@link https://developer.mozilla.org/en-US/docs/Web/API/URL/URL}
// @see {@link https://github.com/whatwg/url/issues/531}
return Object.keys(b).forEach(a=>{const c=b[a];if(["number","string"].includes(typeof c))f.searchParams.set(a,c);else if(Array.isArray(c)){const b=c.filter(a=>!Array.isArray(a));b.length&&b.forEach(b=>{f.searchParams.append(a,b);});}}),e?f.href.replace(f.origin,""):f.href});var appendQueryParams$1 = appendQueryParams;

const KNOWN_DBH_QUERY_PARAMETERS=["dbhq","guidBooking",// Booking and post booking pages.
"internalGuidBooking",// Booking and post booking pages.
"code",// Booking and post booking pages.
"email",// `ResetPasswordPage`.
"token"// `ResetPasswordPage`.
],isAllowedKey=a=>"string"==typeof a&&(isAnalyticsQueryParameter(a)||KNOWN_DBH_QUERY_PARAMETERS.includes(a)),pickQueryParamsForRedirect=a=>a?Object.keys(a).reduce((b,c)=>{const d=b;return isAllowedKey(c)&&(d[c]=a[c]),d},{}):{};/**
 * Is the input an "Analytics" related key (@example `utm_source`) or a known
 * "DBH" key.
 * @param {string} key Query parameter key to check.
 * @return {boolean} Is `key` an "Analytics" or otherwise known "DBH" key that
 * we want to append to the query when redirecting.
 *//**
 * Given a `req.query` like object, returns a subset of all its "Analytics"
 * or otherwise known "DBH" related keys (@example `utm_source`, `guidBooking`).
 * @param {Object?} query Mapping between query keys and query parameter values.
 * @return {Object} Subset of all the keys of the `query` that we want to keep
 * when redirecting.
 * @see {@link https://expressjs.com/en/4x/api.html#req.query}
 */var pickQueryParamsForRedirect$1 = pickQueryParamsForRedirect;

/**
 * Validates an `API` `URL` that includes an `URL` query parameter.
 * @param {string} apiUrl .
 * @example
 * Valid: https://apidev.daybreakhotels.com/v0.1/IT/it-IT/webpages?url=/IT/it-IT/35/BookingFormPage
 * Invalid: https://apidev.daybreakhotels.com/v0.1/IT/it-IT/webpages?url=/IT/en-GB/35/BookingFormPage
 * Invalid: https://apidev.daybreakhotels.com/v0.1/FR/fr-FR/webcontrols?url=/IT/it-IT/35/BookingFormPage
 * @param {Object} options .
 * @param {string} options.expectedCountry .
 * @param {string} options.expectedLocale .
 * @return {boolean} .
 */const validateApiUrlWithUrlQueryParam=withConformsTo("validateApiUrlWithUrlQueryParam",[],()=>{// We do not execute this check in production (speed).
return !0});var validateApiUrlWithUrlQueryParam$1 = validateApiUrlWithUrlQueryParam;

/**
 * Returns the `URL` parts from the given `URL`.
 * @param {string} relativeUrl .
 * @return {Object} .
 */const getUrlPartsFromRelativeUrl=withConformsTo("getUrlPartsFromRelativeUrl",[],a=>{const{pathname:b,hash:c,search:d}=new URL(a,EXAMPLE_URL);return {pathname:b,hash:c,search:d}});var getUrlPartsFromRelativeUrl$1 = getUrlPartsFromRelativeUrl;

const urlFragmentIdentifierPropType=_noop;const hostnamePropType=_noop;

export { EXAMPLE_URL, URL_PRODUCTION_WWW, appendQueryParams$1 as appendQueryParams, convertUrlToDBHDomain$1 as convertUrlToDBHDomain, endsWithSlash$1 as endsWithSlash, extractQueryParametersFromUrl$1 as extractQueryParametersFromUrl, getCountryAndLocaleFromUrl$1 as getCountryAndLocaleFromUrl, getCountryFromUrl, getLocaleFromUrl, getTrailingNumberFromPathname$1 as getTrailingNumberFromPathname, getUrlPartsFromRelativeUrl$1 as getUrlPartsFromRelativeUrl, hostnamePropType, isExternalUrl$1 as isExternalUrl, isLocalAnchorLink$1 as isLocalAnchorLink, isPossiblyValidCountry, isPossiblyValidLocale, isRelativeUrl$1 as isRelativeUrl, isUrlFragmentIdentifier$1 as isUrlFragmentIdentifier, isUrlPhoneNumber$1 as isUrlPhoneNumber, isUrlStagingOrLocalhost$1 as isUrlStagingOrLocalhost, isValidUrl$1 as isValidUrl, pickQueryParamsForRedirect$1 as pickQueryParamsForRedirect, transformWebpagesDataForStaging$1 as transformWebpagesDataForStaging, urlFragmentIdentifierPropType, validateApiUrlWithUrlQueryParam$1 as validateApiUrlWithUrlQueryParam, validateReactRouterLinkObject };
