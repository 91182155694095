import _noop from 'lodash/noop';
import 'prop-types';

const colors={transparent:"transparent",white:"#FFFFFF",black:"#262626",blackMid:"#4C4C4C",blackLight:"#989898",grey:"#35312D",greyMid:"#73706D",greyLight:"#BEBEBE",greyUltralight:"#EAEAEA",greySuperlight:"#FAFAFA",brandFirst:"#C2262E",brandFirstMid:"#E1696F",brandFirstLight:"#EAB5B9",brandSecond:"#38C7B4",brandSecondMid:"#3CD2BE",brandSecondLight:"#EDF9F8",brandThird:"#557E90",brandThirdMid:"#89A5B2",brandThirdLight:"#EDF1F4",brandFifth:"#FFCD09",brandFifthMid:"#FEDC6E",brandFifthLight:"#FFECB6",brandSeventh:"#42AE54",brandEighth:"#846CB6",facebookBrand:"#3B5998",googleBorder:"#4285F4",link:"#007BFF",linkHover:"#0056B3"};const valuesColor=Object.keys(colors);

const spacing={// `auto` is not a valid value for `padding`.
auto:"auto",0:"0",sp0:"0",sp1:".2rem",sp2:".4rem",sp3:"0.8rem",sp4:"1.2rem",sp5:"1.6rem",sp6:"2rem",sp7:"2.4rem",sp8:"3.2rem",sp9:"4rem",sp10:"4.8rem",sp11:"5.2rem",sp12:"6rem",sp13:"7.5rem",sp14:"8rem",sp15:"9rem",sp16:"10rem",sp17:"12rem",sp18:"14rem",sp19:"15rem",sp20:"18rem",sp21:"20rem",sp22:"22rem",sp23:"23rem",sp24:"26rem",sp25:"28rem",sp26:"30rem",sp27:"32rem",sp28:"34rem",sp29:"38rem",sp30:"44rem"};const valuesSpacing=Object.keys(spacing);

const size={0:"0",ff0:"4rem",ff1:"3.6rem",ff2:"3.2rem",ff3:"2.8rem",ff4:"2.4rem",ff5:"2rem",ff6:"1.8rem",ff7:"1.6rem",ff8:"1.4rem",ff9:"1.2rem",ff10:"1rem",404:"20rem",inherit:"inherit"},style={light:"300",normal:"400",medium:"500",bold:"700",italic:"italic"},align={left:"left",center:"center",right:"right",justify:"justify"},transform={lowercase:"lowercase",uppercase:"uppercase",capitalize:"capitalize",none:"none"},lineHeight={l1:"1.1",l2:"1.3",l3:"1.5"},valuesSize=Object.keys(size),valuesStyle=Object.keys(style),valuesAlign=Object.keys(align),valuesTransform=Object.keys(transform),valuesLineHeight=Object.keys(lineHeight),fonts={size,style,align,transform,lineHeight};

const colorPropType=_noop,sizePropType=_noop,stylePropType=_noop,lineHeightPropType=_noop,alignPropType=_noop,transformPropType=_noop;

/*
 *
 * Constants: `@dbh/theme-values`.
 *
 */const Z_INDEX_THEME_KEY="zIndices";

export { Z_INDEX_THEME_KEY, alignPropType, colorPropType, colors, align as fontAligns, size as fontSizes, style as fontStyles, transform as fontTransforms, fonts, lineHeight, lineHeightPropType, sizePropType, spacing, stylePropType, transformPropType, valuesAlign, valuesColor, valuesLineHeight, valuesSize, valuesSpacing, valuesStyle, valuesTransform };
