import { Map } from 'immutable';
import _noop from 'lodash/noop';
import 'prop-types';
import '@dbh/generic-types';
import { createSimpleSelector } from '@dbh/reselect-extra';
import { createSelector } from 'reselect';
import Cookie from 'universal-cookie';
import { getImmutableCookieAnonymousId, getImmutableCookieGuidSession } from '@dbh/app-cookies';

const SSR_DATA_REDUX_AND_SAGA_KEY="ssrData";const SET_COMPUTED_DATA_FROM_SERVER_SIDE_RENDERING="@dbh/ssr-data-redux/SET_COMPUTED_DATA_FROM_SERVER_SIDE_RENDERING";const EMPTY_HASHES_IN_SSR_STATE_UPDATED_TO_BROWSER_HASH="@dbh/ssr-data-redux/EMPTY_HASHES_IN_SSR_STATE_UPDATED_TO_BROWSER_HASH";const SSR_STARTED="@dbh/ssr-data-redux/SSR_STARTED";const SSR_DATA_STATE_SLICE_ADAPTED_AFTER_SSR_FINISHED="@dbh/ssr-data-redux/SSR_DATA_STATE_SLICE_ADAPTED_AFTER_SSR_FINISHED";

const initialState=Map({}),CF_SSR_ONLY_KEYS_TO_DELETE=["cfGeolocationDataNotForBrowsers","cfGeolocationDataHeadersForSsrRequests"],ssrDataReducer=function(a,b){void 0===a&&(a=initialState);const{type:c}=b;switch(c){case SET_COMPUTED_DATA_FROM_SERVER_SIDE_RENDERING:{const{payload:{anonymousId:a,guidTab:c,guidSession:d,locationOriginFromServer:e,locationHrefFromServer:f,queryFromServer:g,httpRequestToServerSideRendering:h,frontEndApiEnvCookieValue:i,frontEndApiDomainCookieValue:j}}=b;return Map({anonymousId:a,guidTab:c,guidSession:d,locationOriginFromServer:e,locationHrefFromServer:f,queryFromServer:g,httpRequestToServerSideRendering:h,frontEndApiEnvCookieValue:i,frontEndApiDomainCookieValue:j})}case EMPTY_HASHES_IN_SSR_STATE_UPDATED_TO_BROWSER_HASH:{const{payload:{hash:c}}=b;// The browsers by design do not send the `window.location.hash` to the
// servers, so the `locationHrefFromServer` in the `redux` state sent
// by our server to the browser, will never contain the `hash`. Since
// for analytics purposes we want it to include the `hash` if that `hash`
// is the `URL` field of an `admindev` "Web Page" that is a hash page,
// this event "fixes" the `locationHrefFromServer` as soon as possible
// when the application is loaded in the browser.
return a.update("locationHrefFromServer",a=>""+a+c)}case SSR_DATA_STATE_SLICE_ADAPTED_AFTER_SSR_FINISHED:// We cannot send this geolocation data to the browsers, both for
// the `GDPR` and because it's not needed.
return a.update("httpRequestToServerSideRendering",a=>{const b=a.deleteAll(CF_SSR_ONLY_KEYS_TO_DELETE);return b});default:return a}};// @TODO `jest` test.
var ssrDataReducer$1 = ssrDataReducer;

/**
 * Store the data originating from the 'Express.js' request object and generally
 * from server side rendering (SSR) in the store.
 * @param {Object} options .
 * @param {string} options.locationOriginFromServer The location given by the server.
 * @param {string} options.anonymousId The value of the tracking cookie that identifies
 * the user.
 * @param {string} options.guidTab The unique identifier that identifies a "browser tab",
 * meaning an instance of this single page application.
 * @param {string} options.guidSession The value of the session cookie.
 * @param {string} options.locationHrefFromServer The location given by the server.
 * @param {Object} options.queryFromServer Mapping between query names and values.
 * @param {Object} options.frontEndApiEnvCookieValue .
 * @param {Object} options.frontEndApiDomainCookieValue .
 * @param {string[]} options.activeConsentGroups .
 * @param {Immutable.Map} options.httpRequestToServerSideRendering The data from the
 * first request that the client (browser or bot) sent to our `express.js`
 * server (such request effectively started the server side rendering).
 * @return {Object} .
 */const setComputedDataFromServerSideRendering=a=>{let{locationOriginFromServer:b,anonymousId:c,guidTab:d,guidSession:e,locationHrefFromServer:f,queryFromServer:g,httpRequestToServerSideRendering:h,frontEndApiEnvCookieValue:i,frontEndApiDomainCookieValue:j,activeConsentGroups:k}=a;return {type:SET_COMPUTED_DATA_FROM_SERVER_SIDE_RENDERING,payload:{locationOriginFromServer:b,anonymousId:c,guidTab:d,guidSession:e,locationHrefFromServer:f,queryFromServer:g,httpRequestToServerSideRendering:h,frontEndApiEnvCookieValue:i,frontEndApiDomainCookieValue:j,activeConsentGroups:k}}};/**
 * The browser by design does not send `window.location.hash` to the server.
 * We dispatch this event as soon as possible when `react` is loaded in the
 * browser, to "correct" `locationHrefFromServer` that we use for pixels and
 * tracking by appending the current `HashPage` `hash` to it. Note that if
 * the `hash` is some random (`#something`), invalid, unrecognized `hash` this
 * event should not be dispatched.
 * @param {Object} param .
 * @param {string} param.hash @example `#dbh/sign-in`.
 * @return {Object} .
 */const emptyHashesInSsrStateUpdatedToBrowserHash=a=>{let{hash:b}=a;return {type:EMPTY_HASHES_IN_SSR_STATE_UPDATED_TO_BROWSER_HASH,payload:{hash:b}}};/**
 * Server side rendering started.
 * @param {string} url Received from the server.
 * @return {Object} .
 */const ssrStarted=(a/* From Express.js. */)=>({type:SSR_STARTED,url:a});const ssrStartedPropType=_noop;/**
 * Dispatched when server side rendering is complete, this event is triggered
 * to remove the `Cloudflare` headers from the store. We don't want to keep
 * them in the store after server-side rendering is complete.
 * @return {Object}.
 */const ssrDataStateSliceAdaptedAfterSsrFinished=()=>({type:SSR_DATA_STATE_SLICE_ADAPTED_AFTER_SSR_FINISHED});

const selectDataFromSsr=a=>a.get(SSR_DATA_REDUX_AND_SAGA_KEY);var selectDataFromSsr$1 = selectDataFromSsr;

const createSsrDataSelectorFor=a=>createSimpleSelector(selectDataFromSsr$1,Array.isArray(a)?a:[a]);var createSsrDataSelectorFor$1 = createSsrDataSelectorFor;

/**
 * Returns a selector that returns the `HTTP` request data and device data from
 * server side rendering.
 * @return {Immutable.Map} .
 */const makeSelectHttpRequestToServerSideRendering=()=>createSsrDataSelectorFor$1("httpRequestToServerSideRendering");var makeSelectHttpRequestToServerSideRendering$1 = makeSelectHttpRequestToServerSideRendering;

/**
 * Returns a selector that returns `true` if the device that requested our
 * site is considered to be a bot (we match its user agent against a list).
 * @return {boolean} `true` if the device must be considered a "bot".
 */const makeSelectIsBot=()=>createSimpleSelector(makeSelectHttpRequestToServerSideRendering$1(),["isBot"]);var makeSelectIsBot$1 = makeSelectIsBot;

/**
 * Returns a selector that returns `true` if the detected device (if a device
 * has been detected and if we also have the width of such device) is considered
 * by us to be "mobile", based on its screen pixels width. We generally consider
 * a device to be mobile if its breakpoint is `<= MD`.
 * @return {boolean} .
 * @see `@dbh/ensure-device-detection-headers-middleware`.
 */const makeSelectIsDetectedDeviceBreakpointMobile=()=>createSimpleSelector(makeSelectHttpRequestToServerSideRendering$1(),["detectedDeviceData","isMobile"]);var makeSelectIsDetectedDeviceBreakpointMobile$1 = makeSelectIsDetectedDeviceBreakpointMobile;

/**
 * Returns the location `href` of the landing page. It is the complete `URL`,
 * including, if present, the query string.
 * @return {string} @example `https://test.daybreakhotels.com/IT/en-GB/lorem?ipsum=amet`.
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/Location}
 */const selectLocationHrefFromServer=createSsrDataSelectorFor$1("locationHrefFromServer");var selectLocationHrefFromServer$1 = selectLocationHrefFromServer;

/**
 * Returns the location origin of the landing page.
 * @return {string} @example `https://test.daybreakhotels.com`.
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/Location}
 */const selectLocationOriginFromServer=createSsrDataSelectorFor$1("locationOriginFromServer");var selectLocationOriginFromServer$1 = selectLocationOriginFromServer;

/**
 * Returns a selector that returns the breakpoint and other device screen data,
 * of the device detected in server side rendering (from the user agent).
 * The device detection data sources are "50 degrees" (`nginx`
 * module, compiled inside `Docker`, and `@dbh/openddr-middleware`).
 * @return {Immutable.Map} .
 * @see {@link https://developer.apple.com/library/archive/documentation/DeviceInformation/Reference/iOSDeviceCompatibility/Displays/Displays.html}
 */const makeSelectDetectedDeviceBreakpointAndScreenData=()=>createSimpleSelector(makeSelectHttpRequestToServerSideRendering$1(),["detectedDeviceData"]);var makeSelectDetectedDeviceBreakpointAndScreenData$1 = makeSelectDetectedDeviceBreakpointAndScreenData;

/**
 * Returns a selector that returns the computed breakpoints used for rendering.
 * @return {Immutable.Map} .
 */const makeSelectComputedSSrBreakpointsForRendering=()=>createSimpleSelector(makeSelectHttpRequestToServerSideRendering$1(),["computedBreakpointsUsedForRendering"]);var makeSelectComputedSSrBreakpointsForRendering$1 = makeSelectComputedSSrBreakpointsForRendering;

/**
 * @typedef {Object} SsrDataIds
 * @property {string} anonymousId .
 * @property {string} guidTab .
 * @property {string} guidSession .
 *//**
 * Returns a selector that returns the `anonymousId`, `guidTab` and `guidSession`.
 * @return {SsrDataIds} .
 */const makeSelectSsrDataIds=()=>createSelector(selectDataFromSsr$1,a=>{const b=["anonymousId","guidTab","guidSession"].reduce((b,c)=>{const d=a.get(c);return d&&(b[c]=d),b},{});return b});var makeSelectSsrDataIds$1 = makeSelectSsrDataIds;

const makeSelectGeneratedTrackingIds=()=>createSelector(makeSelectSsrDataIds$1(),a=>{const{anonymousId:b,guidSession:c}=a,d=new Cookie;return {...a,// If the `anonymousId` is not available from the `SSR` data, then we try to
// get it from the `cookie`. This could happen if the user visits the
// site for the first time without giving consent to the cookies before.
// The `anonymousId` is generated only when the user gives consent to the
// cookies.
// @see `@dbh/cookie-consent#useInjectCookieConsentCallback`.
// @see `serverEntry/manageServerSideCookies`.
anonymousId:b||getImmutableCookieAnonymousId(d)||null,guidSession:c||getImmutableCookieGuidSession(d)||null}});var makeSelectGeneratedTrackingIds$1 = makeSelectGeneratedTrackingIds;

const detectedDeviceDataImmutablePropType=_noop;

export { EMPTY_HASHES_IN_SSR_STATE_UPDATED_TO_BROWSER_HASH, SET_COMPUTED_DATA_FROM_SERVER_SIDE_RENDERING, SSR_DATA_REDUX_AND_SAGA_KEY, SSR_DATA_STATE_SLICE_ADAPTED_AFTER_SSR_FINISHED, SSR_STARTED, createSsrDataSelectorFor$1 as createSsrDataSelectorFor, detectedDeviceDataImmutablePropType, emptyHashesInSsrStateUpdatedToBrowserHash, makeSelectComputedSSrBreakpointsForRendering$1 as makeSelectComputedSSrBreakpointsForRendering, makeSelectDetectedDeviceBreakpointAndScreenData$1 as makeSelectDetectedDeviceBreakpointAndScreenData, makeSelectGeneratedTrackingIds$1 as makeSelectGeneratedTrackingIds, makeSelectHttpRequestToServerSideRendering$1 as makeSelectHttpRequestToServerSideRendering, makeSelectIsBot$1 as makeSelectIsBot, makeSelectIsDetectedDeviceBreakpointMobile$1 as makeSelectIsDetectedDeviceBreakpointMobile, makeSelectSsrDataIds$1 as makeSelectSsrDataIds, selectDataFromSsr$1 as selectDataFromSsr, selectLocationHrefFromServer$1 as selectLocationHrefFromServer, selectLocationOriginFromServer$1 as selectLocationOriginFromServer, setComputedDataFromServerSideRendering, ssrDataReducer$1 as ssrDataReducer, ssrDataStateSliceAdaptedAfterSsrFinished, ssrStarted, ssrStartedPropType };
